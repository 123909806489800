import { memo, useCallback, useEffect, useMemo } from "react"
import CopyToClipboard from "react-copy-to-clipboard"

import { ReactComponent as MoreVerticalIcon } from "@spatialsys/assets/icons/lucide/more-vertical.svg"
import { isAdminOfSAPIRoom } from "@spatialsys/js/sapi/helpers"
import { SAPILobbyType, SpaceMetadata } from "@spatialsys/js/sapi/types"
import {
  InteractionName,
  InteractionType,
  TrackedComponentByMount,
  TrackedComponents,
  useTrackInteraction,
} from "@spatialsys/react/analytics"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { getShareUrl } from "@spatialsys/url-utils"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { Modals } from "@spatialsys/web/app-state"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
  cn,
} from "@spatialsys/web/ui"

import { useUser } from "../../user/user-query-hooks"

const Id = TrackedComponents.SpacesListItemOverflowMenu

const DROPDOWN_MENU_ITEM_CLASSES = "font-heading text-xs font-semibold no-underline"

type OverflowMenuProps = {
  /**
   * Forces the menu closed.
   */
  forceClose?: boolean
  /**
   * If true, renders the 3-dot icon. Otherwise, it is hidden (opacity 0)
   * On small screens, the 3-dot icon is always rendered and this prop is ignored.
   */
  isVisible?: boolean
  space: SpaceMetadata
  onCopyUrl: () => void
  onDelete: () => void
  onRename: () => void
  onSetAsBanner?: () => void
  onUpgradePlanClicked?: () => void
}

/**
 * Overflow menu for a space card, giving options like copy link, rename space, etc.
 * This menu renders in non-modal, allowing the page to be scrolled and elements behind the menu to be interacted with.
 */
export const OverflowMenu = memo(function OverflowMenu(props: OverflowMenuProps) {
  const { forceClose, isVisible, space, onDelete, onRename, onCopyUrl, onSetAsBanner, onUpgradePlanClicked } = props

  // we externally control the state so that we can close the menu when `forceClose` is true
  const [isOpen, setIsOpen] = useBoolean()
  const { isAuthenticated } = useAuthState()
  const { user } = useUser()
  const trackInteraction = useTrackInteraction()

  const copyToClipboardUrl = useMemo(() => getShareUrl(space), [space])
  const isAdmin = isAdminOfSAPIRoom(space, user?.id)
  const isOwner = space.ownerID === user?.id
  const canUpgradePlan = isOwner
  const canReport = !isAdmin && !isOwner && isAuthenticated
  const canRename = isAdmin
  const canDelete = isOwner && !(space.activeUserCount > 0) && space.lobbyType === SAPILobbyType.None

  const actions = useAppContext((context) => context.actions)

  useEffect(() => {
    if (forceClose) setIsOpen.setFalse()
  }, [forceClose, setIsOpen])

  const openReportSpaceModal = useCallback(() => {
    actions.openModal({ type: Modals.ReportSpace, payload: { spaceID: space.id, spaceName: space.name } })
  }, [actions, space.id, space.name])

  const setAsBanner = useCallback(() => {
    if (!onSetAsBanner) return undefined
    onSetAsBanner()
    trackInteraction({
      name: InteractionName.SetProfileBackgroundBanner,
      type: InteractionType.Click,
      component: Id,
    })
  }, [onSetAsBanner, trackInteraction])

  const onCopy = useCallback(() => {
    onCopyUrl()
    trackInteraction({
      name: InteractionName.CopyShareUrl,
      type: InteractionType.Click,
      component: Id,
    })
  }, [onCopyUrl, trackInteraction])
  const rename = useCallback(() => {
    onRename()
    trackInteraction({ name: InteractionName.RenameSpace, type: InteractionType.Click, component: Id })
  }, [onRename, trackInteraction])
  const deleteSpace = useCallback(() => {
    onDelete()
    trackInteraction({ name: InteractionName.DeleteSpace, type: InteractionType.Click, component: Id })
  }, [onDelete, trackInteraction])

  return (
    <DropdownMenu modal={false} open={isOpen} onOpenChange={setIsOpen.set}>
      <DropdownMenuTrigger
        className={cn(
          "flex h-6 w-6 items-center justify-center rounded-full opacity-100 duration-150 hover:bg-accent sm:h-7 sm:w-7 xl:h-8 xl:w-8",
          "data-[state=open]:opacity-100",
          isVisible ? "opacity-100" : "xs:opacity-0"
        )}
      >
        <span className="sr-only">More actions</span>
        <MoreVerticalIcon className="icon icon-xs sm:icon-sm" />
      </DropdownMenuTrigger>

      <DropdownMenuPortal>
        <DropdownMenuContent align="end">
          <TrackedComponentByMount id={Id}>
            {canUpgradePlan && onUpgradePlanClicked && (
              <DropdownMenuItem className={DROPDOWN_MENU_ITEM_CLASSES} onClick={onUpgradePlanClicked}>
                Upgrade Plan
              </DropdownMenuItem>
            )}
            {onSetAsBanner && (
              <DropdownMenuItem className={DROPDOWN_MENU_ITEM_CLASSES} onClick={setAsBanner}>
                Set Banner
              </DropdownMenuItem>
            )}
            <CopyToClipboard onCopy={onCopy} text={copyToClipboardUrl} options={{ format: "text/plain" }}>
              <DropdownMenuItem className={DROPDOWN_MENU_ITEM_CLASSES}>
                <span>Copy Link</span>
              </DropdownMenuItem>
            </CopyToClipboard>
            {canReport && (
              <DropdownMenuItem className={DROPDOWN_MENU_ITEM_CLASSES} onClick={openReportSpaceModal}>
                Report
              </DropdownMenuItem>
            )}
            {canRename && (
              <DropdownMenuItem className={DROPDOWN_MENU_ITEM_CLASSES} onClick={rename}>
                Rename
              </DropdownMenuItem>
            )}
            {canDelete && (
              <DropdownMenuItem
                className={cn(DROPDOWN_MENU_ITEM_CLASSES, "text-red hover:text-red data-[highlighted]:text-red")}
                onClick={deleteSpace}
              >
                Delete
              </DropdownMenuItem>
            )}
          </TrackedComponentByMount>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
})
