import { SpaceMetadata } from "@spatialsys/js/sapi/types"
import { useAuthState } from "@spatialsys/web/app-context"

import { useIsMobile } from "../../../hooks/use-is-mobile"
import { LoveSpaceButton } from "../../love-space-button/love-space-button"
import { LiveBadge } from "../../space-badges/live-badge"

type ThumbnailOverlayProps = {
  handleUnauthenticatedClick: () => void
  isLoadingLoved: boolean
  showLoveButton: "mobile-only" | "always"
  space: SpaceMetadata
}
export function ThumbnailOverlay(props: ThumbnailOverlayProps) {
  const { handleUnauthenticatedClick, isLoadingLoved, showLoveButton, space } = props
  const { isAuthenticatingOrLoggingIn } = useAuthState()
  const isMobile = useIsMobile(false)

  return (
    <div className="pointer-events-none absolute inset-0">
      <div className="absolute left-2 top-2 z-10">
        <LiveBadge activeUserCount={space.activeUserCount} isLive={space.live} />
      </div>

      {!isLoadingLoved && (showLoveButton === "always" || isMobile) && (
        <div className="absolute right-2 top-2 ">
          <LoveSpaceButton
            classNameContainer="icon-md drop-shadow"
            handleUnauthenticatedClick={handleUnauthenticatedClick}
            hideLoveCount
            isDisabled={isAuthenticatingOrLoggingIn}
            isLoved={space.liked}
            loveCount={space.likeCount}
            spaceId={space.id}
          />
        </div>
      )}
    </div>
  )
}
