import { memo, useMemo } from "react"

import { pricingPlanIdToName } from "@spatialsys/js/sapi/helpers"
import { PricingPlanId } from "@spatialsys/js/sapi/types"
import { Text, cn } from "@spatialsys/web/ui"

type SpaceSubscriptionBadgeProps = { pricingPlanId: PricingPlanId; className?: string; labelClassName?: string }

export const SpaceSubscriptionBadge = memo(function SpaceSubscriptionBadge(props: SpaceSubscriptionBadgeProps) {
  const { pricingPlanId, className, labelClassName } = props
  const label = useMemo(() => {
    return pricingPlanIdToName(pricingPlanId)
  }, [pricingPlanId])

  return (
    <div
      className={cn(
        "flex items-center justify-center rounded-md bg-accent px-2 py-1",
        {
          "bg-blue": pricingPlanId === "PRO" || pricingPlanId === "BUSINESS" || pricingPlanId === "ENTERPRISE",
        },
        className
      )}
    >
      <Text size="sm" weight="bold" className={labelClassName}>
        {label}
      </Text>
    </div>
  )
})
