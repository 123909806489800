import { memo, useCallback, useRef } from "react"

import { ReactComponent as NavigateBeforeIcon } from "@spatialsys/assets/icons/material-filled/navigate-before.svg"
import { ReactComponent as NavigateNextIcon } from "@spatialsys/assets/icons/material-filled/navigate-next.svg"
import { useHorizontalScrollable } from "@spatialsys/web/core/js/hooks/use-horizontal-scrollable"
import { cn } from "@spatialsys/web/ui"

type HorizontalScrollableProps = React.PropsWithChildren<{ className?: string; classNameArrow?: string }>

/**
 * Wraps its children in a container that can be scrolled horizontally with buttons.
 */
export const HorizontalScrollable = memo(function HorizontalScrollable({
  className,
  classNameArrow,
  children,
}: HorizontalScrollableProps) {
  const innerContainerRef = useRef<HTMLDivElement>(null)

  const { canScrollLeft, canScrollRight, scrollToLeft, scrollToRight, updateCanScroll } =
    useHorizontalScrollable(innerContainerRef)

  const setInnerContainer = useCallback(
    (el: HTMLDivElement) => {
      innerContainerRef.current = el
      updateCanScroll()
    },
    [updateCanScroll]
  )

  return (
    <div className={cn("relative max-w-full overflow-hidden", className)}>
      <button
        className={cn(
          "absolute bottom-0 top-0 z-10 hidden h-full items-center bg-background text-base opacity-0 shadow-[0_0_8px_8px] shadow-background transition-opacity  duration-100 hover:bg-accent",
          "left-0",
          canScrollLeft && "flex opacity-100",
          classNameArrow
        )}
        onClick={() => scrollToLeft()}
      >
        <NavigateBeforeIcon className="icon" />
      </button>
      <button
        className={cn(
          "absolute bottom-0 top-0 z-10 hidden h-full items-center bg-background text-base opacity-0 shadow-[0_0_8px_8px] shadow-background transition-opacity duration-100 hover:bg-accent",
          "right-0",
          canScrollRight && "flex opacity-100",
          classNameArrow
        )}
        onClick={() => scrollToRight()}
      >
        <NavigateNextIcon className="icon" />
      </button>
      <div className="overflow-x-scroll hide-scrollbar" ref={setInnerContainer} onScroll={updateCanScroll}>
        {children}
      </div>
    </div>
  )
})
