import Link from "next/link"
import { memo } from "react"

import { SpaceAndCreator } from "@spatialsys/js/sapi/types"
import { SetBoolean } from "@spatialsys/react/hooks/use-boolean"
import { formatSpacePath } from "@spatialsys/url-utils"
import { Text, cn } from "@spatialsys/web/ui"

import { LinkOrButton } from "../../link-or-button/link-or-button"

export type SpaceNameAndCreatorProps = {
  openCreatorProfileInNewTab: boolean
  asButton: boolean
  spaceAndCreator: SpaceAndCreator
  onClick: (spaceData: SpaceAndCreator) => void
  spaceHovered?: boolean
  setSpaceHovered?: SetBoolean
  disableTabFocus?: boolean
}

const getSetHoveredProps = (setHovered?: SetBoolean) => ({
  onMouseEnter: setHovered?.setTrue,
  onMouseLeave: setHovered?.setFalse,
  onFocus: setHovered?.setTrue,
  onBlur: setHovered?.setFalse,
})

export const SpaceNameAndCreator = memo(function SpaceNameAndCreator(props: SpaceNameAndCreatorProps) {
  const {
    openCreatorProfileInNewTab,
    spaceAndCreator: { space, creator },
    asButton,
    onClick,
    spaceHovered,
    setSpaceHovered,
    disableTabFocus,
  } = props
  const spacePath = formatSpacePath({ id: space.id, slug: space.slug, shareId: space.shareID })
  const creatorPath = `/@${creator.username}`

  return (
    <>
      <LinkOrButton
        className="w-full no-underline"
        asButton={asButton}
        linkHref={spacePath}
        onClick={() => onClick(props.spaceAndCreator)}
        {...getSetHoveredProps(setSpaceHovered)}
        tabIndex={disableTabFocus && -1}
      >
        <Text size="base" className="line-clamp-1 text-left text-sm font-bold normal-case 2xl:text-base">
          <UnderlineContainer isHovered={spaceHovered}>{space.name}</UnderlineContainer>
        </Text>
      </LinkOrButton>
      <Link
        href={creatorPath}
        className="line-clamp-1 w-fit break-all text-xs text-muted-foreground no-underline transition-colors duration-200 hover:text-foreground/90"
        target={openCreatorProfileInNewTab ? "_blank" : undefined}
        rel={openCreatorProfileInNewTab ? "noreferrer" : undefined}
        tabIndex={disableTabFocus && -1}
      >
        {creator.displayName}
      </Link>
    </>
  )
})

export function UnderlineContainer({ children, isHovered }: { children: string; isHovered: boolean }) {
  return (
    <span
      className={cn(
        "bg-gradient-to-r from-foreground to-foreground bg-[length:0_2px] bg-left-bottom bg-no-repeat transition-[background-size] duration-200",
        isHovered && "bg-[length:100%_2px]"
      )}
    >
      {children}
    </span>
  )
}
