import { PropsWithChildren } from "react"

import { cn } from "@spatialsys/web/ui"

type TagProps = PropsWithChildren<{ className?: string; clickable?: boolean }>

export const Tag = (props: TagProps) => {
  const { children, className, clickable = false } = props
  return (
    <div
      className={cn(
        "rounded bg-gray-200 px-2 py-1 text-xs font-demibold",
        clickable && "transition duration-200 hover:bg-accent",
        className
      )}
    >
      {children}
    </div>
  )
}
