import clsx from "clsx"
import { ReactNode, memo, useEffect, useRef } from "react"
import { CSSTransition } from "react-transition-group"

import { SpaceMetadata } from "@spatialsys/js/sapi/types"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { cn } from "@spatialsys/web/ui"

type ThumbnailProps = {
  thumbnailUrl: string
  sharpCorners?: boolean
  isVideoPlaying?: boolean
  darkenImageWhileVideoLoading?: boolean
  videoSrc?: ReactNode
} & Pick<SpaceMetadata, "name">

export const Thumbnail = memo(function Thumbnail(props: ThumbnailProps) {
  const { darkenImageWhileVideoLoading, thumbnailUrl, name, sharpCorners = false, videoSrc, isVideoPlaying } = props
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isVideoLoaded, setVideoLoaded] = useBoolean(false)

  useEffect(() => {
    if (isVideoPlaying === false && isVideoLoaded) {
      setVideoLoaded.setFalse()
    }
  }, [isVideoLoaded, isVideoPlaying, setVideoLoaded])

  useEffect(() => {
    if (!videoRef.current) return
    if (isVideoPlaying === false) {
      videoRef.current?.pause()
    } else {
      void videoRef.current?.play().catch(() => {})
    }
  }, [isVideoPlaying])

  return (
    <div className={clsx("relative h-full w-full", sharpCorners && "rounded-none")}>
      <div className="absolute h-full w-full bg-black" />
      <CSSTransition
        in={videoSrc && isVideoPlaying !== false}
        nodeRef={videoRef}
        mountOnEnter
        unmountOnExit
        timeout={150}
        classNames={{ exitActive: "opacity-0" }}
      >
        <video
          ref={videoRef}
          onEnded={() => {
            void videoRef.current?.play().catch(() => {})
          }}
          onCanPlayThrough={setVideoLoaded.setTrue}
          muted
          loop
          disableRemotePlayback
          disablePictureInPicture
          playsInline
          className={cn(
            "absolute inset-0 h-full w-full object-cover transition-opacity duration-150",
            isVideoLoaded ? "opacity-100" : "opacity-0"
          )}
        >
          {videoSrc}
        </video>
      </CSSTransition>
      <img
        loading="lazy"
        src={thumbnailUrl}
        alt={name}
        className={cn(
          "absolute inset-0 h-full w-full object-cover transition-opacity duration-150",
          // While video is loading, darken the image to show some feedback
          videoSrc &&
            isVideoPlaying !== false &&
            (isVideoLoaded ? "opacity-0" : darkenImageWhileVideoLoading && "opacity-70")
        )}
      />
    </div>
  )
})
