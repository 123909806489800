import { memo, useCallback, useMemo, useRef } from "react"
import ReactPlayer from "react-player"
import { useHoverDirty } from "react-use"

import { SpaceAndCreator, SpaceMetadata } from "@spatialsys/js/sapi/types"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { useGetSpaceLimitsQuery } from "@spatialsys/react/query-hooks/spaces-v2/get-space-limits"
import { SpaceItemClickMetadata } from "@spatialsys/unity/bridge"
import { formatSpacePath } from "@spatialsys/url-utils"
import { useAppContext } from "@spatialsys/web/app-context"
import { Modals } from "@spatialsys/web/app-state"
import classes from "@spatialsys/web/core/css/components/space-poster-preview.module.scss"
import { cn, toast } from "@spatialsys/web/ui"

import { useIsMobile } from "../../hooks/use-is-mobile"
import { LinkOrButton } from "../link-or-button/link-or-button"
import { LoveCount } from "../social-signals/love-count"
import { ViewCount } from "../social-signals/view-count"
import { SpaceSubscriptionBadge } from "../space-subscriptions/space-subscription-badge"
import { useUser } from "../user/user-query-hooks"
import { SpacesGridItemHoverPreview } from "./hover-preview/hover-preview"
import { OverflowMenu } from "./overflow-menu/overflow-menu"
import { SpaceNameAndCreator } from "./space-name-and-creator/space-name-and-creator"
import { Thumbnail } from "./thumbnail"
import { ThumbnailOverlay } from "./thumbnail-overlay/thumbnail-overlay"

export type SpacesGridItemProps = {
  /** If true, renders a button instead of a link */
  asButton?: boolean
  darkenImageWhileVideoLoading?: boolean
  handleDeleteSpace: (space: SpaceMetadata) => void
  handleLogin: () => void
  handleRenameSpace: (space: SpaceMetadata) => void
  /** Callback when the tile is pressed. */
  onSelect: (space: SpaceAndCreator, metadata: SpaceItemClickMetadata) => void
  /**
   * If true, renders a button in the overflow menu to "set as banner"
   */
  handleSetBanner?: (space: SpaceMetadata) => void
  /** If true, opens the creator profile in a new tab */
  openCreatorProfileInNewTab?: boolean
  spaceData: SpaceAndCreator
  spaceListIndex: number
  /**
   * If true, will hide the socialSignals to avoid immediate UI shifting
   *
   * Defaults to false.
   */
  isLoadingLoved?: boolean
  hideOverflowMenu?: boolean
  hideThumbnail?: boolean
  showHoverPreview?: boolean
  disableTabFocus?: boolean
  isPreview?: boolean
  onThumbnailPointerOver?: (index: number) => void
  isVideoThumbnailPlaying?: boolean
  showSpaceSubscriptionPlan?: boolean
}

export const SpacesGridItem = memo(function SpacesGridItem(props: SpacesGridItemProps) {
  const {
    asButton = false,
    darkenImageWhileVideoLoading,
    handleDeleteSpace,
    handleLogin,
    handleRenameSpace,
    onSelect,
    handleSetBanner,
    spaceData,
    isLoadingLoved = false,
    openCreatorProfileInNewTab = false,
    hideOverflowMenu = false,
    hideThumbnail = false,
    spaceListIndex,
    showHoverPreview = true,
    disableTabFocus,
    isPreview,
    onThumbnailPointerOver,
    isVideoThumbnailPlaying,
    showSpaceSubscriptionPlan,
  } = props
  // Have to keep track of this state in code because we want unified animation between
  // non-sibling elements. Can use `:has()` selector for this instead when widely available.
  const [thumbnailHovered, setThumbnailHovered] = useBoolean(false)
  const [spaceNameHovered, setSpaceNameHovered] = useBoolean(false)
  const [hoverPreviewHovered, setHoverPreviewHovered] = useBoolean(false)
  const spaceHovered = thumbnailHovered || spaceNameHovered

  const isMobile = useIsMobile()

  const { user } = useUser()
  const { space } = spaceData
  const spacePath = formatSpacePath({ id: space.id, slug: space.slug, shareId: space.shareID })
  const { data: spaceLimits } = useGetSpaceLimitsQuery(space.id)
  const isOwner = space.ownerID === user?.id

  // Overflow menu
  const containerRef = useRef<HTMLDivElement>(null)
  const isHovering = useHoverDirty(containerRef)

  const actions = useAppContext((context) => context.actions)

  const handleCopy = useCallback(() => {
    toast("Copied link to clipboard!")
  }, [])

  const handleThumbnailPointerOver = useCallback(() => {
    onThumbnailPointerOver?.(spaceListIndex)
  }, [onThumbnailPointerOver, spaceListIndex])

  const handleUpgradePlan = useCallback(() => {
    actions.openModal({
      type: Modals.SpaceSubUpgrade,
      payload: { source: "default", spaceId: space.id },
    })
  }, [actions, space.id])

  const videoSrc = useMemo(() => {
    if (!space.customVideo?.webmLowRes && !space.customVideo?.mp4) return undefined
    return (
      <>
        {space.customVideo?.webmLowRes && <source src={space.customVideo?.webmLowRes} type="video/webm" />}
        {space.customVideo?.mp4 && <source src={space.customVideo?.mp4} type="video/mp4" />}
      </>
    )
  }, [space.customVideo?.mp4, space.customVideo?.webmLowRes])

  return (
    <div className="relative" ref={containerRef}>
      <div
        className={cn(
          "relative isolate mb-2 aspect-video w-full overflow-hidden rounded-lg bg-black/20 transition-shadow duration-200",
          spaceHovered && "shadow-[0_4px_8px] shadow-black/30"
        )}
      >
        {!hideThumbnail && (
          <LinkOrButton
            className="absolute inset-0"
            asButton={asButton}
            linkHref={spacePath}
            onClick={() => onSelect(spaceData, { "Space Index": spaceListIndex, "On Space Thumbnail": true })}
            onPointerOver={handleThumbnailPointerOver}
            onMouseEnter={setThumbnailHovered.setTrue}
            onMouseLeave={setThumbnailHovered.setFalse}
            onFocus={setThumbnailHovered.setTrue}
            onBlur={setThumbnailHovered.setFalse}
            tabIndex={disableTabFocus ? -1 : undefined}
          >
            {/* Video thumbnails are only on mobile, desktop has hover preview */}
            {isMobile ? (
              <Thumbnail
                darkenImageWhileVideoLoading={darkenImageWhileVideoLoading}
                thumbnailUrl={space.thumbnail}
                name={space.name}
                isVideoPlaying={isVideoThumbnailPlaying}
                videoSrc={videoSrc}
              />
            ) : (
              <Thumbnail thumbnailUrl={space.thumbnail} name={space.name} />
            )}
          </LinkOrButton>
        )}
        {isPreview && (
          <div className={classes.videoContainer}>
            <ReactPlayer
              url={space.customVideo?.mp4}
              playing
              loop
              stopOnUnmount
              playsinline
              width="100%"
              height="100%"
            />
          </div>
        )}
        <ThumbnailOverlay
          handleUnauthenticatedClick={handleLogin}
          isLoadingLoved={isLoadingLoved}
          showLoveButton="mobile-only"
          space={space}
        />
        {isOwner && showSpaceSubscriptionPlan && spaceLimits?.plan && (
          <SpaceSubscriptionBadge
            pricingPlanId={spaceLimits.plan}
            className="absolute right-2 top-2"
            labelClassName="text-xs"
          />
        )}
      </div>
      <div className="grid grid-cols-[minmax(0,1fr)_auto] gap-3">
        <div className="w-full">
          <SpaceNameAndCreator
            spaceHovered={spaceHovered}
            setSpaceHovered={setSpaceNameHovered}
            openCreatorProfileInNewTab={openCreatorProfileInNewTab}
            asButton={asButton}
            spaceAndCreator={spaceData}
            onClick={() => onSelect(spaceData, { "Space Index": spaceListIndex, "On Space Name": true })}
            disableTabFocus={disableTabFocus}
          />
          {!isPreview && (
            <div className="text-xs text-muted-foreground">
              <ViewCount numViews={space.joinCount} />
              <span className="px-0.5">•</span>
              <LoveCount numLoves={space.likeCount} />
            </div>
          )}
        </div>

        {!hideOverflowMenu && (
          <OverflowMenu
            forceClose={thumbnailHovered || hoverPreviewHovered}
            isVisible={isHovering}
            space={space}
            onCopyUrl={handleCopy}
            onDelete={() => handleDeleteSpace(space)}
            onRename={() => handleRenameSpace(space)}
            onSetAsBanner={handleSetBanner ? () => handleSetBanner?.(space) : undefined}
            onUpgradePlanClicked={showSpaceSubscriptionPlan ? handleUpgradePlan : undefined}
          />
        )}
      </div>

      {showHoverPreview && !isMobile && (
        <SpacesGridItemHoverPreview
          hideOverflowMenu={hideOverflowMenu}
          isHovering={thumbnailHovered || hoverPreviewHovered}
          spaceData={spaceData}
          openCreatorProfileInNewTab={openCreatorProfileInNewTab}
          asButton={asButton}
          onSelectSpace={(space, metadata) =>
            onSelect(space, { ...metadata, "Space Index": spaceListIndex, "On Hover Card": true })
          }
          isLoadingLoved={isLoadingLoved}
          handleCopy={handleCopy}
          handleDeleteSpace={handleDeleteSpace}
          handleLogin={handleLogin}
          handleRenameSpace={handleRenameSpace}
          handleSetBanner={handleSetBanner}
          spaceListIndex={spaceListIndex}
          onMouseEnter={setHoverPreviewHovered.setTrue}
          onMouseLeave={setHoverPreviewHovered.setFalse}
        />
      )}
    </div>
  )
})
